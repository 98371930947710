import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Bill of Lading",
  "description": "The Bill of Lading refers to a legal document used in shipping as an evidence of carriage contract and cargo receipt.",
  "author": "Ally Cheng",
  "categories": ["shipping"],
  "date": null,
  "featured": false,
  "tags": ["shipping"],
  "relatedArticles": []
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "what-is-the-bill-of-lading"
    }}>{`What Is The Bill of Lading?`}</h2>
    <p><strong parentName="p">{`A Bill of Lading (B/L)`}</strong>{` is one of the legal documents used for shipping globally.`}</p>
    <p>{`The B/L has 2 major functions:`}</p>
    <ul>
      <li parentName="ul">{`As evidence for the contract of carriage`}</li>
      <li parentName="ul">{`As a receipt of goods purchased or sold`}</li>
    </ul>
    <p>{`Generally, for shipments through air and sea freight, draft documents are issued before the customs declaration. A formal bill of lading is issued in within a week after the ship sails. `}</p>
    <h2 {...{
      "id": "classifying-bill-of-lading"
    }}>{`Classifying Bill of Lading`}</h2>
    <p>{`1`}{`.`}{` Based on the goods' loading status`}</p>
    <ol>
      <li parentName="ol">{`Shipped B/L or on Board B/L`}</li>
      <li parentName="ol">{`Received for Shipment B/L`}</li>
    </ol>
    <p>{`2`}{`.`}{` Based on who has control over the shipment`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Straight B/L, also known as the consignee's bill of lading.`}</p>
        <p parentName="li">{`This means that the consignee has control over the shipment.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Order B/L`}</p>
        <p parentName="li">{`Order B/L means that the shipment is controlled by an appointed party.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Blank B/L or Open B/L`}</p>
        <p parentName="li">{`This means the document is left unfilled or partially empty and is available to be used by anyone else.`}</p>
      </li>
    </ol>
    <p>{`3`}{`.`}{` Based on the goods' condition upon shipping receipt`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Clean B/L`}</p>
        <p parentName="li">{`A clean bill of lading is issued when the goods received upon shipment are in good condition and are expected to be received at the same conditions.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Foul B/L`}</p>
        <p parentName="li">{`A foul bill of lading is issued when the goods received have problems with their surface or packaging. Banks usually won't accept foul B/L when handling foreign settlements.`}</p>
      </li>
    </ol>
    <p>{`4`}{`.`}{` Based on the payment method`}</p>
    <ol>
      <li parentName="ol">{`Freight Prepaid B/L.`}</li>
      <li parentName="ol">{`Freight Collect B/L.`}</li>
    </ol>
    <p>{`5`}{`.`}{` Based on the shipment operation modes`}</p>
    <ol>
      <li parentName="ol">{`Liner B/L`}</li>
      <li parentName="ol">{`Charter Party B/L`}</li>
    </ol>
    <h2 {...{
      "id": "bill-of-lading-details"
    }}>{`Bill of Lading Details`}</h2>
    <p>{`A Bill of Lading should include these details:`}</p>
    <ul>
      <li parentName="ul">{`Shipper information`}</li>
      <li parentName="ul">{`Consignee information`}</li>
      <li parentName="ul">{`Notified Party`}</li>
      <li parentName="ul">{`Loading Party`}</li>
      <li parentName="ul">{`Place of Receipt`}</li>
      <li parentName="ul">{`Ocean Vessel `}</li>
      <li parentName="ul">{`Loading Port`}</li>
      <li parentName="ul">{`Port of Discharge`}</li>
      <li parentName="ul">{`Place of Delivery (Recipient Address)`}</li>
      <li parentName="ul">{`Marks and numbers, container numbers, and seal numbers`}</li>
      <li parentName="ul">{`Number of containers or P kgs`}</li>
      <li parentName="ul">{`Type of packaging, description of goods `}</li>
      <li parentName="ul">{`Gross Weight (kgs)`}</li>
      <li parentName="ul">{`Volume `}</li>
      <li parentName="ul">{`Freight and charges, where to pay, and how to pay`}</li>
      <li parentName="ul">{`Bill of lading number and original bill of lading (B/L No., No. of Original B(s)/L)`}</li>
      <li parentName="ul">{`Place and Date of Issue`}</li>
      <li parentName="ul">{`Carrier signature`}</li>
    </ul>
    <h2 {...{
      "id": "difference-between-bill-of-landing-bl-and-delivery-order-do"
    }}>{`Difference between Bill of Landing (B/L) and Delivery Order (D/O)`}</h2>
    <p>{`B/L: BILL OF LADING is the document of title to the goods.`}</p>
    <p>{`D/O: DELIVERY ORDER bill of lading, which is the document required when picking up the goods at the destination port, and cannot be transferred.`}</p>
    <p>{`After the goods are on board, the shipping company issues B/L to SHIPPER, and SHIPPER transfers the B/L to CONSIGNEE. When the goods arrive at the port, CONSIGNEE goes to the shipping company for D/O with the B/L and picks up the goods at the port with the D/O.`}</p>
    <p>{`One and only one of the two remains in the hands of the cargo owner before the cargo is picked up, but the D/O is no longer a document of title. After the cargo owner receives the D/O, it means that the shipping company has released the cargo to the cargo owner`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      